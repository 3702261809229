* {
  margin: 0;
}

.app {
  /*background-image: linear-gradient(#ff00d4, #21023d);*/
  background-color:black;
  background-image:
  radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
  radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
  radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
  radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px);
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
  color: #fff;
  height: 100%;
}

.app__posts {
  padding: 20px;
  display: flex;
  justify-content: center;
}

@keyframes move-bg {
  to {
    background-position: var(--bg-size) 0;
  }
}

.app__intro {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.app__introContents {
  background-color: rgb(0, 0, 0);
  border: 1px solid #00f7ff;
  border-radius: 10px;
  max-width: 660px;
  justify-content: center;
  padding: 20px;
}

.app__introArrow {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  filter: invert(20%) sepia(82%) saturate(2793%) hue-rotate(297deg) brightness(114%) contrast(142%);
}

.app__introArrow img {
  width: 4%;
}

.wiggly-text {
  --bg-size: 400%;
  --color-one: #ff00d4;
  --color-two: rgb(0, 247, 255);
  font-size: 18px;
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 15s infinite linear;
}

.app__header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #000000;
  padding: 20px;
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  filter: drop-shadow(0 0.2rem 2rem rgba(255, 0, 212, 0.5));
}

.app__headerImage {
  object-fit: contain;
  width: 20%;
}

.app_signup {
  display: flex;
  flex-direction: column;
}

.app__footer {
  padding: 2rem;
  background-color: black;
}

@media screen and (max-width: 700px) {
  .wiggly-text {
    --bg-size: 400%;
    --color-one: #ff00d4;
    --color-two: #00f7ff;
    font-size: 12px;
    background: linear-gradient(
                  90deg,
                  var(--color-one),
                  var(--color-two),
                  var(--color-one)
                ) 0 0 / var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: move-bg 15s infinite linear;
  }

  .app__intro {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 10px;
  }
  
  .app__introContents {
    background-color: rgb(0, 0, 0);
    border: 1px solid #00f7ff;
    border-radius: 10px;
    justify-content: center;
  }
  
  .app__introArrow {
    display: flex;
    justify-content: center;
    filter: invert(20%) sepia(82%) saturate(2793%) hue-rotate(297deg) brightness(114%) contrast(142%);
  }
  
  .app__introArrow img {
    width: 10%;
  }
  
  .app__posts {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .app__headerImage {
    object-fit: contain;
    width: 50%;
  }

  .app__footer {
    font-size: 10px;
  }
}