.imageupload {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.imageupload__progress {
    width: 100%;
}

.imageupload__caption {
    height: fit-content;
}