.post {
    background-color: rgb(0, 0, 0);
    color: white;
    max-width: 700px;
    border: 1px solid #ff00d4;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 30px;
}

.post__image {
    width: 100%;
    object-fit: contain;
    border-top: 1px solid #ff00d4;
    border-bottom: 1px solid #ff00d4;
}

.post__text {
    font-weight: normal;
    padding: 10px;
}

.post__text-strong {
    color: #ff00d4;
}

.post__header {
    display: flex;
    align-items: center;
    padding: 10px;
    color: #ff00d4;
}

.post__avatar {
    margin-right: 10px;
}